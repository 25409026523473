import styled from 'styled-components';

const BannerStyled = styled.div`
    position: fixed;
    width: 100vw;
    padding: 0px;
    height: 60px;
    background-color: #f59656;
    color: #fff;
    z-index: 1000;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
        height: 60px;
        margin-right: 10px;
    }

    a {
        color: #f59656;
        font-weight: bold;
        background-color: #fff;
        padding: 10px;
        margin-left: 10px;
        border: 1px solid #fff;

        &:hover {
            background-color: #f59656;
            color: #fff;
            border: 1px solid #fff;
        }
    }

    @media screen and (max-width: 767px) {
        top: 84px;
        right: 0;
        padding-bottom: 10px;
        postition: relative;

        a {
            position: absolute;
            right: 30%;
            bottom: -20px;
        }

        p {
            max-width: 80%;
        }
    }
`;

export default BannerStyled;