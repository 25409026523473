import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import HeaderStyled from "./HeaderStyled";
import logo from "assets/logo-crossfitossau.png";
import logoBelier from "assets/logo-belier.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

const Header = () => {
  const anim = () => {
    let navLinks = document.querySelectorAll(".picto-rs-container a");

    navLinks.forEach((link) => {
      link.addEventListener("mouseover", (e) => {
        let attrX = e.offsetX - 20;
        let attrY = e.offsetY - 13;

        link.style.transform = `translate( ${attrX}px, ${attrY}px)`;
      });
      link.addEventListener("mouseleave", () => {
        link.style.transform = "";
      });
    });
  };

  return (
    <HeaderStyled>
      <div className="small-ligne"></div>
      <NavLink to="/">
        <img src={logoBelier} alt="logo de cf ossau - Bélier musclé" />
      </NavLink>
      <nav>
        <ul>
          <li>
            <a href="https://www.crossfitbielle.fr/#box" className="active box">
              La box
            </a>
          </li>
          <li>
            <a
              href="https://www.crossfitbielle.fr/#objectif"
              className="objectif"
            >
              Vos objectifs
            </a>
          </li>
          <li>
            <a
              href="https://www.crossfitbielle.fr/#solutions"
              className="crossfit"
            >
              Nos solutions
            </a>
          </li>
          <li>
            <a
              href="https://www.crossfitbielle.fr/#planning"
              className="planning"
            >
              Planning
            </a>
          </li>
          <li>
            <a href="https://www.crossfitbielle.fr/#equipe" className="equipe">
              L'équipe
            </a>
          </li>
          {/*<li><a href="https://www.crossfitbielle.fr/#tarif"  className="tarif">Tarifs</a></li>*/}
          <li>
            <a
              href="https://www.crossfitbielle.fr/#contact"
              className="contact"
            >
              Contact
            </a>
          </li>
        </ul>
      </nav>
      <div className="big-ligne"></div>
      <div className="picto-rs-container">
        <a
          href="https://www.facebook.com/Cfossau"
          onMouseOver={anim}
          target="_blank"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://www.instagram.com/crossfit_bielle/?hl=fr"
          onMouseOver={anim}
          target="_blank"
        >
          <InstagramIcon />
        </a>
        <a href="mailto:contact@crossfitbielle.fr" onMouseOver={anim}>
          <EmailIcon />
        </a>
      </div>
      <div className="small-ligne"></div>
    </HeaderStyled>
  );
};

Header.propTypes = {};

export default Header;
