import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import SimpleMenuStyled from "./SimpleMenuStyled";
import MenuIcon from "@material-ui/icons/Menu";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import logo from "assets/logo-crossfitossau.png";
import logoBelier from "assets/logo-belier.png";

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <SimpleMenuStyled>
      <div className="mobile-menu">
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <a href="#box" className="active box">
              La box
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a href="#objectif" className="crossfit">
              Vos objectifs
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a href="#solutions" className="equipe">
              Nos solutions
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a href="#planning" className="tarif">
              Planning
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a href="#equipe" className="planning">
              L'équipe
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a href="#contact" className="contact">
              Contact
            </a>
          </MenuItem>
        </Menu>
      </div>
      <div className="picto-rs-container">
        <a href="https://www.facebook.com/Cfossau" target="_blank">
          <FacebookIcon />
        </a>
        <a
          href="https://www.instagram.com/crossfit_bielle/?hl=fr"
          target="_blank"
        >
          <InstagramIcon />
        </a>
        <a href="mailto:crossfitossau@gmail.com">
          <EmailIcon />
        </a>
      </div>
      <img src={logoBelier} alt="logo de cf ossau - Bélier musclé" />
    </SimpleMenuStyled>
  );
}
