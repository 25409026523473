// == Import : npm
import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
// == Import : local
import rootReducer from 'reducers'
// == Enhancers
// on fait en sorte d'avoir accès au devtool s'il est installé
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancers = composeEnhancers(
  applyMiddleware(
  ),
)

const persistConfig = {
  key: 'user',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['user'] // which reducer want to store
}

const pReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  pReducer,
  enhancers,
)
export const persistor = persistStore(store)