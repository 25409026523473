import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import PropTypes from "prop-types";
import { InlineWidget } from "react-calendly";
import Slider from "react-slick";

import ContactForm from "components/ContactForm";
import ModalLegal from "components/ModalLegal";
import Banner from "components/Banner";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import GroupIcon from "@material-ui/icons/Group";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

import PageStyled from "./PageStyled";
import video from "assets/video.mp4";
import background from "assets/background.jpg";
import logo from "assets/logo-crossfitossau.png";
import peppy from "assets/peppy.png";
import eleiko from "assets/eleiko2.png";
import logoBelier from "assets/logo-belier.png";
import profil from "assets/profil.jpg";
import profils from "assets/profils.jpg";
import emma from "assets/emma.jpg";
import intfossau from "assets/intfossau.jpg";
import christophe from "assets/extcfossau.jpg";
import planning from "assets/planningbielle.png";
import Fist from "assets/fist.png";
import mana from "assets/mana.png";
import coachemma from "assets/coachemma.jpg";
import coachchristophe from "assets/coachchristophe.jpg";
import coacharthur from "assets/coacharthure.jpg";
import coachpauline from "assets/coachpauline.jpg";

import car1 from "assets/car1.jpg";
import car2 from "assets/car2.jpg";
import car3 from "assets/car3.jpg";
import car4 from "assets/car4.jpg";
import car5 from "assets/car5.jpg";
import car6 from "assets/car6.jpg";
import car7 from "assets/car7.jpg";
import car8 from "assets/car8.jpg";
import car10 from "assets/car10.jpg";
import car11 from "assets/car11.jpg";
import car12 from "assets/car12.jpg";

const Page = ({}) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const [showModal, setShowModal] = useState(false);

  const [videoLoaded, setVideoLoaded] = useState(true);

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const handleVideoEnd = () => {
    setVideoLoaded(false);
  };

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  var settings = {
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    variableWidth: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  return (
    <PageStyled>
      <ModalLegal showModal={showModal} setShowModal={setShowModal} />
      {/*<Banner />*/}

      <section
        id="box"
        className="section-scroll box"
        style={{
          backgroundImage: `url("${background}")`,
          backgroundSize: "cover",
        }}
      >
        {videoLoaded ? (
          <video
            autoPlay
            muted
            className="video-wrapper"
            onLoadedData={handleVideoLoad}
            onEnded={handleVideoEnd}
            onError={() => setVideoLoaded(false)}
          >
            <source src={video} type="video/mp4" />
            Votre navigateur ne prend pas en charge la balise vidéo.
          </video>
        ) : (
          <div className="opacity-img">
            <div className="slider-container">
              <Slider {...settings}>
                <div>
                  <img src={car1} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car2} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car3} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car4} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car5} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car6} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car7} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car8} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car10} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car11} alt="Photo athlete cf bielle" />
                </div>
                <div>
                  <img src={car12} alt="Photo athlete cf bielle" />
                </div>
              </Slider>
            </div>
            <div>
              <h1>
                <span className="one">F</span>
                <span className="two">i</span>
                <span className="three">t</span>
                <span className="four">n</span>
                <span className="five">e</span>
                <span className="six">s</span>
                <span className="seven"> s</span>
                <span className="height"></span>
                <span className="nine"> B</span>
                <span className="ten">i</span>
                <span className="eleven">e</span>
                <span className="twelve">l</span>
                <span className="thirteen">l</span>
                <span className="fourteen">e</span>
              </h1>
              <hr className="redline grow" />
              <p className="slogan">« Révèle ton potentiel ! »</p>
              <div className="offre-lancement">
                <p>Offre de lancement 49€/mois.</p>
                <p>Offre limitée ! Renseignements sur place.</p>
              </div>
            </div>
          </div>
        )}
      </section>

      <section id="objectif" className="section-scroll crossfit">
        <div className="wrapper-section">
          <h2 data-aos="fade-up">Pour quels objectifs ?</h2>
          <hr className="redline" data-aos="fade-up" />
        </div>
        <div className="objectif-wrapper">
          <div className="box">
            <div>1</div>Perte de poids
          </div>
          <div className="box">
            <div>2</div>Douleurs chroniques
          </div>
          <div className="box">
            <div>3</div>Éviter la sédentarité
          </div>
          <div className="box">
            <div>4</div>Devenir plus souple
          </div>
          <div className="box">
            <div>5</div>Renforcer les muscles profonds
          </div>
          <div className="box">
            <div>6</div>Entretenir sa forme physique
          </div>
        </div>
        <div data-aos="fade-up" className="btn-wrapper">
          <a
            href="https://calendly.com/crossfitbielle/jereservemoncoursgratuit"
            className="btn-resa"
          >
            Je prends rdv pour un bilan
          </a>
        </div>
      </section>

      <section id="solutions" className="section-scroll crossfit">
        <div className="wrapper-section">
          <h2 data-aos="fade-up">S'entraîner</h2>
          <hr className="redline" data-aos="fade-up" />
          <div className="def-container">
            <div data-aos="fade-up" className="div-container">
              <div className="wrapper-title-icon">
                <FitnessCenterIcon />
              </div>
              <div className="p">
                <h3>Des cours collectifs !</h3>
                <ul>
                  <li>
                    RENFO :{" "}
                    <span>
                      Renforcement et entretien musculaire d’une heure. Ce cours
                      est de tout niveau et permet à chacun d’entretenir sa
                      tonicité musculaire. Tous niveaux !
                    </span>
                  </li>
                  <li>
                    WOD :{" "}
                    <span>
                      En anglais WOD signifie "Workout Of the Day" autrement dit
                      "l'entraînement du jour". Séance avec des mouvements très
                      variés (mélangeant 3 disciplines : gymnastique,
                      haltérophilie et cardio). Tous les exercices sont adaptés
                      au niveau de chacun !
                    </span>
                  </li>
                  <li>
                    GYM :{" "}
                    <span>
                      Module spécifique visant à apprendre et/ou améliorer ses
                      capacités en gymnastique suspendue et gymnastique au sol.
                    </span>
                  </li>
                  <li>
                    HIIT :{" "}
                    <span>
                      Cours de 45min 100% cardio. Sous forme de circuit, nous
                      travaillons à haute intensité cardio afin d’améliorer son
                      souffle au quotidien. Tous niveaux !
                    </span>
                  </li>
                  <li>
                    YOGA :{" "}
                    <span>
                      Mélange de Yoga Dynamique et de Mobilité articulaire pour
                      améliorer vos postures au quotidien . Tous niveaux !
                    </span>
                  </li>
                  <li>
                    MOBILITÉ :{" "}
                    <span>
                      Cours basé sur l’amélioration de la souplesse et
                      l’amplitude articulaire, musculaire et tendineuse afin
                      d’éviter les tensions et blessures.Tous niveaux !
                    </span>
                  </li>
                  <li>
                    CONDITIONING :{" "}
                    <span>
                      Séance axée sur des efforts variés (faibles à modérés)
                      pour améliorer les capacités cardio-vasculaires et la
                      résistance musculaire. Tous niveaux !
                    </span>
                  </li>
                  <li>
                    KIDS / TEENS :{" "}
                    <span>
                      séance ludique pour les enfants de 3 à 6 et de 6 à 14 ans
                      !
                    </span>
                  </li>
                  <li>
                    Renfo :{" "}
                    <span>
                      Renforcement et entretien musculaire d’une heure. Ce cours
                      est de tout niveau et permet à chacun d’entretenir sa
                      tonicité musculaire.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div data-aos="fade-up" className="div-container">
              <div className="wrapper-title-icon">
                <GroupIcon />
              </div>
              <div className="p">
                <h3>Etre accompagné(e)</h3>
                <ul>
                  <li>
                    Du coaching particulier :{" "}
                    <span>
                      Les coachs de CFB sont tous diplômés d'État dans la
                      méthodologie d’entraînements et l’encadrement sportif de
                      particulier. Ils proposent chacun des créneaux de coaching
                      privé (1h privatisée avec un coach dans la salle pour
                      répondre à vos objectifs sportifs)
                    </span>
                  </li>
                </ul>
                <a
                  href="https://app.peppy.cool/boxes/cfb/ckrnglacs15279571ko365ns9hif/offers/coaching-particulier/clthh2q4g5494141hpji2evy6f3/checkout/?showWelcome=true&skipPlatformFees=true"
                  target="_blank"
                  className="btn-link"
                >
                  Tarifs et Prise de rendez-vous
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className="btn-wrapper">
          <a
            href="https://calendly.com/crossfitbielle/jereservemoncoursgratuit"
            className="btn-resa"
          >
            J’essaie Gratuitement !
          </a>
        </div>
      </section>

      <section id="planning" className="section-scroll planning">
        <div className="wrapper-section">
          <h2 data-aos="fade-up">Planning</h2>
          <hr className="redline" data-aos="fade-up" />
          <img
            src={planning}
            data-aos="fade-up"
            alt="Photo du planning de la salle"
          />
          <p data-aos="fade-up" className="peppy-planning">
            (Planning susceptible de changer au cours de l'année.)
          </p>
          <p data-aos="fade-up" className="peppy-planning">
            Nous utilisons une application de réservation fonctionnelle et
            intuitive appelée{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.peppygroup.Peppy&gl=FR"
              target="_blank"
            >
              Peppy
            </a>
            , qui vous permet de réserver vos séances à l'avance, entrer vos
            scores, suivre votre évolution et interagir avec votre Box.
          </p>
        </div>
        <div data-aos="fade-up" className="btn-wrapper">
          <a
            href="https://calendly.com/crossfitbielle/jereservemoncoursgratuit"
            className="btn-resa"
          >
            J’essaie Gratuitement !
          </a>
        </div>
      </section>

      <section id="equipe" className="section-scroll equipe">
        <div className="wrapper-section">
          <h2 data-aos="fade-up">Une équipe à l'écoute de vos objectifs</h2>
          <hr className="redline" data-aos="fade-up" />
          <div className="team-container">
            <div data-aos="fade-up" className="coachs">
              <img src={coachemma} alt="Photo de la coach Emma" />
              <h4>Emma</h4>
              <p className="post">Head coach</p>
              <a href="https://www.instagram.com/ma.da.fit/">
                <InstagramIcon />
              </a>
              <p className="description">
                Titulaire d’un BpJeps AGFF double mentions au Creps IDF
              </p>
              <p className="description">
                Passionnée de danse, musculation, sports d’endurance et
                CrossFit.
              </p>
              <p className="description">
                Coach personnel, coach CrossFit, animatrice Tv (émission Gym
                Direct, chaîne C8).
              </p>
              <p className="description">
                Sa philosophie :{" "}
                <span className="italic">
                  « Agissez comme s'il était impossible d'échouer. »
                </span>
              </p>
              <img
                src={coachchristophe}
                alt="Photo de la coach christophe"
                className="margin-top-40"
              />
              <h4>Christophe</h4>
              <p className="post">Coach</p>
              <a href="https://www.instagram.com/christophepuy/">
                <InstagramIcon />
              </a>
              <p className="description">
                Titulaire d'un Bpjeps double mentions haltérophile/musculation
                et cours collectifs ainsi que préparateur physique et mental.
              </p>
              <p className="description">
                Sportif complet, « qui touche à tout » : Cyclisme, Trail,
                Natation, Rugby, Crossfit...
              </p>
              <p className="description">
                Coach personnel, coach CrossFit et préparateur physique à la
                Section Paloise (cadet).
              </p>
              <p className="description italic">
                Sa philosophie :{" "}
                <span className="italic">
                  « Sortez de votre zone de confort. Vous ne pouvez grandir que
                  si vous êtes prêt à vous sentir inconfortable en expérimentant
                  de nouvelles choses. »
                </span>
              </p>
            </div>
            <div data-aos="fade-up" className="team-txt">
              <div className="">L</div>
              <div>a</div>
              <img
                src={Fist}
                alt="icon poing coloré"
                className="fist space-top"
              />
              <div className="space-top">T</div>
              <div>e</div>
              <div>a</div>
              <div>m</div>
            </div>
            <div data-aos="fade-up" className="coachs">
              <img src={coacharthur} alt="Photo de la coach arthur" />
              <h4>Arthur</h4>
              <p className="post">Coach</p>
              <a href="https://www.instagram.com/ma.da.fit/">
                <InstagramIcon />
              </a>
              <p className="description">Diplômé BPJEPS Bi mention et CF-L1</p>
              <p className="description">
                Ancien basketteur de haut niveau, reconverti dans le coaching
                sportif et spécialisé dans le Crossfit et la préparation
                physique.
              </p>
              <p className="description">
                Sa philosophie :{" "}
                <span className="italic">
                  « Ne regrette jamais aucun des choix, bons ou mauvais, ils te
                  serviront à aller de l’avant. »
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section id="tarif" className="section-scroll tarif">
        <div className="wrapper-section">
          <h2 data-aos="fade-up">Tarifs et abonnements</h2>
          <hr className="redline" data-aos="fade-up" />
          
          <div className="tarif-container">
            <div className="tab" data-aos="fade-up">
              <h4>Séances et drop in</h4>
              <div className="offer"><p>Séance d'essai</p><p className="price">Offerte</p></div>
              <div className="point"></div>
              <div className="offer"><p>Séance à l'unité - drop in</p><p className="price">14€</p></div>
              <div className="point"></div>
              <div className="offer"><p>Yoga - séance à l'unité</p><p className="price">14€</p></div>
              <div className="point"></div>
              <div className="offer"><p>Carnet de 10 séances - drop in (Valable 3 mois à partir de la date d’achat)</p><p className="price">129€</p></div>
              <div className="point"></div>
              <div className="offer"><p>Forfait 1 mois</p><p className="price">95€</p></div>
            </div>
            <div className="tab" data-aos="fade-up">
              <h4>Forfaits mensuels*</h4>
              <div className="offer"><p>Forfait 1 entrée : accès limité à une séance par semaine (engagement 13 mois dont le 1er mois est sans engagement) + frais de dossier.</p><p className="price">39€</p></div>
              <div className="point"></div>
              <div className="offer"><p>Forfait spécial : accès illimité seniors + de 60 ans / métiers de la santé et forces spéciales (13 mois dont le 1er mois sans engagement) + frais de dossier</p><p className="price">49€</p></div>
              <div className="point"></div>
              <div className="offer"><p>Forfait classique : accès illimité (13 mois dont le 1er mois sans engagement) + frais de dossier</p><p className="price">64€</p></div>
              <div className="point"></div>
              <div className="offer"><p>Forfait DUO : accès illimité pour deux personnes. Conditions : Frais de dossier à diviser en 2 et payés le jour de la signature // S’inscrire à 2 au même moment.(13 mois dont le 1er mois sans engagement)</p><p className="price">119€</p></div>
              <div className="point"></div>
              <div className="offer"><p>Forfait mensuel sans engagement (accès illimité)</p><p className="price">85€</p></div>
              <div className="point"></div>
              <div className="offer"><p>Forfait yoga : accès aux cours de Yoga uniquement (engagement 13 mois dont le 1er mois est sans engagement) + frais de dossier</p><p className="price">35€</p></div>
              <div className="point"></div>
              <div className="offer"><p>Yoga option - en supplément sur l’abonnement</p><p className="price-small">10€/mois</p></div>
              <div className="point"></div>
              <div className="offer"><p>Frais de dossier à régler à l’inscription</p><p className="price">59€</p></div>
            </div>
            <div class="parainage" data-aos="fade-up">Offre de parrainage : 1 mois offert au parrain à chaque nouvel adhérent parrainé avec abonnement un an.
              Possibilité de cumuler les parrainages.</div>
            <div data-aos="fade-up" className="btn-wrapper">
              <a href="https://calendly.com/crossfitbielle/jereservemoncoursgratuit" className="btn-resa">J’essaie Gratuitement !</a>
            </div></div>
            
        </div>
      </section>
      */}
      <section id="contact" className="section-scroll contact">
        <div className="wrapper-section">
          <h2 data-aos="fade-up">Nous contacter</h2>
          <hr className="redline" data-aos="fade-up" />
          <div className="left-contact">
            <div className="left" data-aos="fade-up">
              <div className="phone-email">
                <h3>Par téléphone ou Email</h3>
                <a href="mailto:contact@crossfitbielle.fr">
                  <EmailIcon /> cfossau@gmail.com
                </a>
                <a href="tel:+33661057598">
                  <PhoneIcon /> 06 61 05 75 98
                </a>
                <img src={logoBelier} alt="logo de cf ossau - Bélier musclé" />
              </div>
              <ContactForm />
            </div>
            <div id="essai">
              <InlineWidget
                url="https://calendly.com/crossfitbielle"
                styles={{
                  width: "100%;",
                }}
              />
            </div>
            <h3>Adresse</h3>
            <p>3 Quartier L'Ayguelade, 64260 Bielle</p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1225.567691405305!2d-0.424395147119581!3d43.06441008845352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd57b1b107f005ed%3A0xfa395c6e2ac00cd9!2sLes%20Boutiques%20d&#39;Ossau!5e0!3m2!1sfr!2sfr!4v1627221205551!5m2!1sfr!2sfr"
              width="100%"
              height="400"
              allowFullScreen=""
              loading="lazy"
              style={{ border: "0" }}
            ></iframe>
          </div>
        </div>
      </section>
      <footer>
        Copyright Crossfit Bielle 2021<span> | </span>
        <span className="span-link" onClick={openModal}>
          Mentions Légales
        </span>
        <span> | </span>
        <NavLink to="/conditions-generales-de-vente" className="span-link" end>
          Conditions générales de vente
        </NavLink>
        <span> | </span>
        <NavLink to="/reglement-interieur" className="span-link" end>
          Règlement intérieur
        </NavLink>
      </footer>
    </PageStyled>
  );
};

Page.propTypes = {};

export default Page;
