import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CgvStyled from './CgvStyled'
import { NavLink } from 'react-router-dom'
import ModalLegal from 'components/ModalLegal'
import ScrollToTop from 'components/ScrollToTop'

const Cgv = () => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(prev => !prev);
  };

  return (
    <CgvStyled>
      <ScrollToTop />
      <ModalLegal showModal={showModal} setShowModal={setShowModal}/>
      <section>
      <h1>CONDITIONS GÉNÉRALES DE VENTE CROSSFIT OSSAU</h1>
      <h2>1/ OBJET :</h2>
      <p>Après avoir visité les installations du CrossFit Bielle et avoir pris connaissances des prestations proposées, du règlement intérieur et des horaires d’ouverture affichés à l’accueil, l’abonné déclare souscrire auprès de CrossFit Bielle un contrat d’adhésion nominatif et incessible.</p>
      <h2>2/ CONDITIONS D’ACCES AU CENTRE :</h2>
      <p>CrossFit Bielle s’engage à accueillir l’adhérent dans les meilleures conditions d’hygiène et de sécurité. L’accès à l’établissement est autorisé à tous les adhérents étant inscrits au club. L’adhérent devra obligatoirement s’inscrire au préalable aux cours proposés via la plateforme de réservation en ligne Peppy pour accéder aux installations et à les utiliser dans le cadre des horaires d’ouverture affichés dont il reconnaît avoir pris connaissance. L’adhérent aura accès à toutes les séances en illimité. Au bout de 3 absences non justifiées, l’adhérent se verra bloquer l’accès à la salle de sport pendant une semaine. L’établissement se réserve le droit de modifier et/ou alléger le planning en fonction des périodes. Il existe 2 fermetures annuelles : fêtes de fin d’année et période estivale dont les dates seront communiquées à l’avance. L’établissement sera fermé la plupart des jours fériés.</p>
      <p>En cas de force majeure (fermeture administrative, crise sanitaire, crise économique, crise mondiale ou autres...), l’établissement se réserve le droit de prendre les mesures adéquates à la situation : fermeture, cours à distance, maintien des prélèvements, cours en extérieur etc...</p>
      <h2>3/ ATTESTATION – CERTIFICAT MÉDICAL :</h2>
      <p>L’adhérent déclare n’avoir aucune contre-indication médicale et s’engage à fournir un certificat médical daté de moins d’un mois « d’Aptitude à la pratique des activités sportives et gymniques et de remise en forme » dans le cadre de l’abonnement souscrit. Le défaut de présentation du certificat médical n’emporte pas caducité du contrat et l’adhérent prend pleine responsabilité de son état de santé en signant ce présent contrat. CrossFit Bielle se dégage de toute responsabilité en cas de non compatibilité avec la pratique.</p>
      <h2>4/ RÈGLEMENT INTÉRIEUR :</h2>
      <p>L’adhérent s’engage à respecter le règlement intérieur sans restriction ni réserve ; les conseils d’utilisations et de sécurité des équipements mis à sa disposition. La Direction se réserve le droit de modifier les clauses de son règlement intérieur sous réserve d’avoir informé sa clientèle 15 jours au préalable.</p>
      <h2>5/ RESPONSABILITES :</h2>
      <p>CrossFit Bielle est assuré pour les dommages engageant sa responsabilité civile et celle de son personnel conformément à la loi du 16 juillet 1984. La responsabilité de CrossFit Bielle ne pourra être recherchée en cas d’accident résultant de l’inobservation des consignes de sécurité ou de l’utilisation inappropriée des appareils ou autres installations.</p>
      <h2>6/ VESTIAIRES :</h2>
      <p>CrossFit Bielle n’est pas responsable des pertes d’objets ou effets personnels. Il est donc conseillé aux adhérents de ne pas avoir sur eux des objets de valeur.</p>
      <h2>7/ CLAUSES FINANCIÈRES - DURÉE – CONTRAT AVEC ENGAGEMENT :</h2>
      <p>Il est prévu une période sans engagement de 30 jours. A l'issue de cette période, le contrat avec engagement est prévu pour une durée minimum de douze mois. A l’issue de cette période de 12 mois, le contrat souscrit sera reconduit par tacite reconduction mensuellement et pourra être résilié à tout moment moyennant le respect d’un préavis de un mois à compter de la réception d’une lettre de résiliation envoyée en recommandé avec avis de réception. Lors du préavis, les cotisations sont dues et prélevées aux dates définies lors de l’adhésion. En aucun cas l’adhésion ne sera remboursée. Pendant ce préavis le contrat produisant toujours effet, l’adhérent pourra continuer à jouir des prestations et équipements de la box</p>
      <h3>CONTRAT SANS ENGAGEMENT :</h3>
      <p>Le contrat souscrit sera reconduit par tacite reconduction mensuellement et pourra être résilié à tout moment sans préavis. L’adhérent devra en informer CrossFit Bielle par courrier simple ou email. Le mois entamé est dû.</p>
      <h3>PARRAINAGE :</h3>
      <p>Un système de parrainage permet de bénéficier d'1 mois offert par adhérent parrainé sur le futur abonnement. Seul le parrainage de nouveaux clients sera accepté. Conditions : abonnement avec engagement souscrit l'adhérent parrainé et le parrain.</p>
      <h3>PAIEMENT :</h3>
      <p>A la signature du contrat, l’adhérent s’engage à payer l’adhésion selon différents modes de règlements et fournir toutes les pièces justificatives et nécessaires pour valider l’adhésion ainsi que l’accès au CrossFit Bielle.</p> 
      <p>L’adhérent s’engage à payer la somme de 64€/mois sur une durée minimum de 13 mois. Les frais d’adhésion s’élèvent à 59€ et se règlent à la première inscription.</p>
      <h3>FRAIS IMPAYÉS :</h3>
      <p>En cas de rejet de prélèvement auprès de votre organisme bancaire pour les abonnements en prélèvements, 20 euros de frais de rejet vous seront facturés par mensualité impayée. En cas de désaccord de consommation, l'adhérent ou l'entreprise CrossFit Bielle pourra faire appel à un médiateur de la consommation.</p> 
      <h2>8/RÉSILIATION – ANNULATION :</h2>
      <p>L’adhérent reconnaît à la direction de CrossFit Bielle le droit d’exclure de l’établissement toute personne dont l’attitude, le comportement ou la tenue seraient contraires aux bonnes mœurs notoirement gênantes pour les autres adhérents, ou non conformes au présent contrat ou règlement intérieur CrossFit Bielle se réserve le droit de résilier unilatéralement le présent contrat en cas de non-respect des conditions générales de vente avec exclusion définitive après mise en demeure par lettre recommandée avec AR. L’abonnement est résilié de plein droit par CrossFit Bielle aux motifs suivants :</p>
      <p>- en cas de fraude</p>
      <p>- en cas de défaut de paiement cité dans la clause n°7 de ce présent contrat.</p>
      <h3>CAS DE FORCE MAJEURE :</h3>
      <p>L’adhérent reconnaît que son abonnement lui ouvre droit à l’utilisation des installations et au bénéfice des prestations énoncées ci-dessus. En cas de non-utilisation définitive de ce droit pour une cause indépendante de sa volonté, en cas de force majeure, l’adhérent peut demander la résiliation de son abonnement par lettre recommandée avec A-R adressé à CrossFit Bielle. La résiliation est effective à l’issu d’un délai de 30 jours à compter de la date de réception de la demande accompagnée des pièces justificatives. Un certificat médical de plus d’un mois antérieur à la réception de la demande de résiliation ne sera pas valide. Par motif de cas de force majeure, il est limitativement fait référence aux cas suivants : En cas de contrat avec engagement, la résiliation peut se faire avant la fin des 12 mois d’engagement pour mutation professionnelle, maladie, accident grave empêchant l’adhérent de bénéficier des services de la box, décès, perte d’emploi avec justificatifs à fournir, déménagement à plus de 50 kms de la box avec une franchise de 2 mois.</p>
      <h2>9/ REPORTS-SUSPENSIONS :</h2>
      <p>Pour toute autre cause d’empêchement non définitif (incapacité temporaire supérieure à 1 mois) : cas de problème de santé ou accident dûment justifié (certificat médical), déplacement professionnel, grossesse empêchant momentanément l’adhérent d’une pratique sportive, l’adhérent bénéficiera, sans supplément de prix, d’une prolongation d’abonnement limitée à la durée notifiée sur le certificat médical.</p>
      <h2>10/ Autorisation au droit à l’image :</h2>
      <p>J’autorise à titre gracieux CrossFit Bielle à fixer et à reproduire mon image ainsi qu’à la modifier. Cette autorisation d’utilisation des images et vidéos est limitée aux cas suivants : site internet, réseaux sociaux et tout autre document publicitaire promotionnel, d’information, commercial ou non commercial</p>
      </section>
      <footer>Copyright Crossfit Bielle 2021<span> | </span>
        <span className="span-link" onClick={openModal}>Mentions Légales</span><span> | </span>
        <NavLink exact to="/conditions-generales-de-vente" className="span-link">Conditions générales de vente</NavLink><span> | </span>
        <NavLink exact to="/reglement-interieur" className="span-link">Règlement intérieur</NavLink>
      </footer>
    </CgvStyled>
  )
}

Cgv.propTypes = {
}

export default Cgv