const initialState = {
    img: ''
}

const carousel = (state = initialState, action = {}) => {
    switch (action.type) {
      default:
        return state;
    }
  };
  
  export default carousel;