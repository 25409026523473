// == Import locaux
import styled from 'styled-components';


// == Composant
// ici on a la fonction qui décrit le style du composant App
const MouseStyled = styled.div`
    .cursor {
        /*width: 10px;
        height: 10px;
        border: 1px solid #f59656;
        border-radius: 50%;*/
        position: absolute;
        transform: translate(-50%, -50%);
        pointer-events: none;
        transition: all 0.5s ease;
        transition-property: background, transform;
        transition: top 160ms ease-out;
        transition: left 160ms ease-out;
        z-index: 2;

        img {
            width: 30px;
        }
    }
    .cursor.hovered {
        img {
            width: 20px;
        }

    @media screen and(max-width 1080px) {
        display: none;
    }
    `

// == Export
export default MouseStyled;