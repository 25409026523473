// == Import : npm
import React from 'react'
// importe du dom virtuel dans le dom reel
import { render } from 'react-dom'
// donne acces au store
import { Provider } from 'react-redux'
// donne acces au router dans toute l'application
import { BrowserRouter as Router } from 'react-router-dom'

// == Import : local
// Composants locaux
import App from 'containers/App'

// Store
import { store } from 'store/index'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

const rootReactElement = (
  <Provider store={store}>
      <Router>
        <App />
      </Router>
  </Provider>
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// 2. La cible du DOM (là où la structure doit prendre vie dans le DOM)
const target = document.getElementById('root')
// 3. Déclenchement du rendu de React (virtuel) => DOM (page web)
render(rootReactElement, target)
