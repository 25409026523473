import React, { Component, useEffect } from "react";
import { Route, Switch, useLocation, Routes } from "react-router-dom";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import AppStyled from "./AppStyled";
import Header from "containers/Header";
import Page from "containers/Page";
import Cgv from "components/Cgv";
import Clock from "components/Clock";
import Reglement from "components/Reglement";
import Mouse from "components/Mouse";
import SimpleMenu from "components/SimpleMenu";

const App = ({}) => {
  const location = useLocation();

  const scrollScript = () => {
    const allSections = document.querySelectorAll(".section-scroll");
    const navLiLeft = document.querySelectorAll("nav ul li a");

    window.addEventListener("scroll", () => {
      let current = "box";
      allSections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop) {
          current = section.getAttribute("id");
        }
      });

      navLiLeft.forEach((a) => {
        a.classList.remove("active");

        if (a.classList.contains(current)) {
          a.classList.add("active");
        }
      });
    });
  };

  useEffect(scrollScript, []);

  return (
    <AppStyled>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <SimpleMenu />
              <Page />
            </>
          }
        />
        <Route
          path="/conditions-generales-de-vente"
          element={
            <>
              <Header />
              <SimpleMenu />
              <Cgv />
            </>
          }
        />
        <Route
          path="/reglement-interieur"
          element={
            <>
              <Header />
              <SimpleMenu />
              <Reglement />
            </>
          }
        />
      </Routes>
    </AppStyled>
  );
};

App.propTypes = {};

export default App;
