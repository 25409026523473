// == Import locaux
import styled from 'styled-components';


// == Composant
// ici on a la fonction qui décrit le style du composant App
const AppStyled = styled.div`
    background: linear-gradient(0deg, rgba(34,25,27,1) 0%, rgba(3,2,2,1) 100%);
    background-color: #000;
    background-size: cover;
    overflow: hidden;

    font-family: Montserrat;
    color: #fff; 
    
    h1, h2, h3, h4, h5, h6, h7 {
        font-family: Bodoni Moda;
        text-transform: uppercase;
        font-weight: bold;
    }

    input, textarea, select {
        border: 0;
    }

    option {
        padding: 0;
    }

    ul.MuiMenu-list {
            background-color: #000;
    }
`;

// == Export
export default AppStyled;