// == Import locaux
import styled from 'styled-components';


// == Composant
// ici on a la fonction qui décrit le style du composant App
const ClockStyled = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    background-color: #000;
    padding: 10px 10px;
    font-family: 'Gemunu Libre', sans-serif;
    display: none;

    p {
        text-align: center;
        padding-bottom: 5px;
        font-size: 22px;
    }

    .clock-wrapper {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;

        .column {
            
            text-align: center;
            width: 100px;
            
            .clock-name {
                padding-bottom: 10px;
            }

            .clock-numbers {
                padding: 0px 0px;
                background-color: #000;
                color: red;
                font-size: 30px;
                font-style: italic;
                width: 100%;
                box-sizing: border-box;
                position: relative;

                .dbp {
                    position: absolute;
                    right: 0;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        bottom: 40px;
        top: auto;
        right: 5%;
        width: 90%;
        box-sizing: border-box;
        padding: 10px 10px;
        display: none;

        p {
            font-size: 16px;
        }

        .clock-wrapper {
    
            .column {
                
                text-align: center;
                width: 50px;
                
                .clock-name {
                    padding-bottom: 10px;
                    font-size: 11px;
                }
    
                .clock-numbers {
                    font-size: 18px;
                }
            }
        }
    }
`;

// == Export
export default ClockStyled;