import styled from 'styled-components';

const HeaderStyled = styled.header`
    width: 150px;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    

    .small-ligne {
        width: 1px;
        background-color: #f59656;
        height: 5vh;
    }

    img {
        max-height: 20vh;
        width: 140px;
        padding-top: 10px;
        bax-sizing: border-box;
    }

    nav ul {
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        
        font-weight: 500;
        font-size: 16px;

        li a.active {
            font-size: 20px !important;
            color: #f59656 !important;
        }
        
        a {
            color: #fff;
            scroll-behavior: smooth;
        }
        a:hover {
            color: #f59656;
        }
    }

    .big-ligne {
        width: 1px;
        background-color: #f59656;
        height: 10vh;
    }

    .picto-rs-container {
        height: 20vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        a {
            transition: 1.1s ease-out;
        }
        
        a:hover {

            svg {
                color: #f59656;
            }
        }

        svg {
            font-size: 3vh;
            color: #fff;
        }
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
`;

export default HeaderStyled;