// == Import locaux
import styled from 'styled-components';


// == Composant
// ici on a la fonction qui décrit le style du composant App
const CgvStyled = styled.div`


section {
    margin: 0px 50px 50px 200px;

    h1 {
        padding: 50px 0px;
        font-size: 25px;
    }

    h2 {
        padding: 40px 0px 20px;
        font-size: 22px;
    }
}
footer {
    width: 100%;
    color: #fff;
    background-color: #000;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;

    .span-link {
        color: #fff;
    }

    .span-link:hover {
        cursor: pointer;
        color: #f59656;
    }
}
`;

// == Export
export default CgvStyled;