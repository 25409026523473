// == Import locaux
import styled from 'styled-components';


// == Composant
// ici on a la fonction qui décrit le style du composant App
const ContactFormStyled = styled.div`
width: 50%;
@media screen and (max-width: 1080px) {
    width: 100%;

    h3 {
        padding-top: 30px;
    }
}
.contact-form {

    .form-content {
        display: grid;
        grid-template-rows: repeat(4, 1fr) 100px;
        margin-bottom: 20px;

    input::placeholder,
    textarea::placeholder,
    select {
        color: #fff;
        font-size:20px;
        font-family: Montserrat;
        font-weight: normal;
    }

    option {
        background: #fff;
        color: #000
    }

    input:focus,
    textarea:focus,
    select:focus,
    .email-content:focus {
        outline: none;
        border-bottom: 1px solid #832700;
    }

    input,
    textarea, 
    select {
        background: none;
        font-size: 20px;
        
        border-bottom: 1px solid #f59656;
        color: #fff;

        @media screen and (min-width: 1200px){
        font-size: 20px;
        }
    }
    input {
        padding: 24px 0 12px;
        width: 100%;

        @media screen and (max-width: 540px){
        padding: 12px 0 6px;
        }
    }
    .email-content {
        position: relative;
        width: 100%;
        

        @media screen and (max-width: 540px){
        }

        #not-mail {
        display: none;
        position: absolute;
        top: 8px;
        color: red;
        right: 0;
        transform-origin: 50% 50%;
        }
        @keyframes dongle {
        0% {
            transform: translate(0px, 0px);
        }
        10% {
            transform: translate(-10px, 0px);
        }
        20% {
            transform: translate(10px, 0px);
        }
        30% {
            transform: translate(-10px, 0px);
        }
        40% {
            transform: translate(10px, 0px);
        }
        50% {
            transform: translate(-10px, 0px);
        }
        60% {
            transform: translate(10px, 0px);
        }
        70% {
            transform: translate(-10px, 0px);
        }
        80% {
            transform: translate(10px, 0px);
        }
        90% {
            transform: translate(-10px, 0px);
        }
        100% {
            transform: translate(0px, 0px);
        }
        }
    }
    textarea {
        resize: none;
        padding: 24px 0;

        @media screen and (max-width: 540px){
        padding: 12px 0;
        }
    }
    }
    .error::placeholder {
    color: red;
    }
    .form-message {
    margin-top: 10px;
    padding: 12px;
    opacity: 0;
    background: #832700;
    transition: 0.3s ease;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
    }
}
`;

// == Export
export default ContactFormStyled;