// == Import locaux
import styled from "styled-components";
import { keyframes } from "styled-components";

const write = keyframes`
0% {opacity: 0;}
100% {opacity: 1;}
`;

const grow = keyframes`
0% {opacity: 0; width: 0vw}
100% {opacity: 1; width: 26vw}
`;

const bounceBackward = keyframes`
0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(7);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: scale(1);
  }

  55% {
    animation-timing-function: ease-in;
    transform: scale(1.5);
  }

  72% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  81% {
    animation-timing-function: ease-in;
    transform: scale(1.24);
  }

  89% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  95% {
    animation-timing-function: ease-in;
    transform: scale(1.04);
  }

  100% {
    animation-timing-function: ease-out;
    transform: scale(1);
    opacity: 1;
  }
`;
// == Composant
// ici on a la fonction qui décrit le style du composant App
const PageStyled = styled.div`
  .video-wrapper {
    width: 100%;
    height: 100%;
    objectfit: cover;
    objectposition: center;
  }

  h1 {
    font-size: 120px;
  }

  h2 {
    text-align: right;
    font-size: 40px;
  }

  h3,
  h4 {
    font-size: 25px;
  }

  #solutions,
  #tarif,
  #equipe {
    background-color: rgba(34, 25, 27, 1);
  }

  #contact,
  #planning {
    background-color: #000;
  }

  section {
    width: 100%;
  }

  .margin-top-40 {
    margin-top: 80px;
  }

  .slogan {
    font-size: 70px;
    font-style: italic;
    opacity: 0;
    animation: ${bounceBackward} 1s ease 1700ms 1 alternate forwards;
  }

  .wrapper-section {
    max-width: 1200px;
    margin: 0 auto;
  }

  #box {
    min-height: 100vh;
    box-sizing: border-box;
    background-position: bottom;

    .slider-container {
      max-height: 400px;

      .slick-slider,
      .slick-list {
        max-height: 400px;
      }

      .slick-slide {
        width: calc(20vw);
        margin: 0 10px;

        img {
          width: calc(20vw);
        }
      }
    }

    .opacity-img {
      background-color: rgba(3, 2, 2, 0.6);
      width: 100%;
      height: 100vh;
      text-align: right;
      padding: 100px 50px 100px 200px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: relative;

      .offre-lancement {
        position: absolute;
        bottom: 80px;
        left: 30%;
        width: 40%;
        height: auto;
        margin: 0px auto;
        background-color: rgba(245, 150, 86, 1);
        text-align: center;
        padding: 20px 0px;
        display: none;

        p:nth-child(1) {
          font-size: 30px;
          padding-bottom: 10px;
        }

        p:nth-child(2) {
          font-size: 18px;
          font-style: italic;
        }
        div {
          margin: 50px 0px -30px;
        }

        a {
          background-color: #fff;
          color: #f59656;
          padding: 20px;
          font-size: 20px;
          font-weight: bold;
          transition: background-color 0.5s;
          margin-top: 40px !important;
        }

        a:hover {
          background-color: #f59656;
          color: #fff;
        }
      }
    }

    h1 {
      color: #fff;

      .one {
        opacity: 0;
        animation: ${write} 100ms ease 100ms 1 normal forwards;
      }
      .two {
        opacity: 0;
        animation: ${write} 100ms ease 300ms 1 normal forwards;
      }
      .three {
        opacity: 0;
        animation: ${write} 100ms ease 500ms 1 normal forwards;
      }
      .four {
        opacity: 0;
        animation: ${write} 100ms ease 700ms 1 normal forwards;
      }
      .five {
        opacity: 0;
        animation: ${write} 0ms ease 900ms 1 normal forwards;
      }
      .six {
        opacity: 0;
        animation: ${write} 0ms ease 1100ms 1 normal forwards;
      }
      .seven {
        margin-left: -2vw;
        opacity: 0;
        animation: ${write} 0ms ease 1300ms 1 normal forwards;
      }
      .height {
        opacity: 0;
        animation: ${write} 0ms ease 1500ms 1 normal forwards;
      }
      .nine {
        opacity: 0;
        animation: ${write} 0ms ease 1700ms 1 normal forwards;
      }
      .ten {
        opacity: 0;
        animation: ${write} 0ms ease 1900ms 1 normal forwards;
      }
      .eleven {
        opacity: 0;
        animation: ${write} 0ms ease 2100ms 1 normal forwards;
      }
      .twelve {
        opacity: 0;
        animation: ${write} 0ms ease 2300ms 1 normal forwards;
      }
      .thirteen {
        opacity: 0;
        animation: ${write} 0ms ease 2500ms 1 normal forwards;
      }
      .fourteen {
        opacity: 0;
        animation: ${write} 0ms ease 2700ms 1 normal forwards;
      }
    }

    .redline {
      border: 0;
      height: 4px;
      background-color: #832700;
      opacity: 0;
      margin-right: 0px;
    }

    .grow {
      animation: ${grow} 1000ms ease 1500ms 1 normal forwards;
    }
  }

  #objectif {
    .objectif-wrapper {
      max-width: 700px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .box {
      background: linear-gradient(45deg, rgb(245, 150, 86), rgb(131, 39, 0));
      width: 200px;
      border-radius: 50%;
      box-sizing: border-box;
      height: 200px;
      display: flex;
      align-items: center;
      margin-top: 50px;
      justify-content: center;
      text-align: center;
      position: relative;
      font-weight: 500;

      div {
        position: absolute;
        top: -10px;
        left: calc(50% - 10px);
        background-color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: rgb(131, 39, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  #solutions {
    .btn-link {
      color: #fff;
      text-decoration: underline;
      transition: color 0.5s;
      padding-top: 20px;
      display: block;
      &:hover {
        color: #f59656;
      }
    }
    ul {
      list-style: square;
      padding-left: 25px;

      li {
        padding-top: 20px;
        color: #f59656;

        span {
          color: #fff;
        }
      }
    }
  }

  #solutions,
  #objectif {
    box-sizing: border-box;
    padding: 100px 0px 100px 0px;

    .crossfit-link {
      padding-top: 20px;

      a {
        color: #fff;
        text-decoration: underline;

        &:hover {
          color: #f59656;
        }
      }
    }

    .redline {
      border: 0;
      height: 4px;
      background-color: #832700;
      margin-right: 0px;
      width: 10vw;
      margin-top: 20px;
    }

    .def-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 50px 0px;
      padding: 0px 20px 100px 20px;
      box-sizing: border-box;

      .div-container {
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        margin-top: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .wrapper-title-icon {
          width: 20%;
          margin-top: 0px;
        }

        svg {
          font-size: 100px;
          color: #832700;
          width: 100%;
        }

        h3 {
          margin: 0 auto;
          text-align: left;
          display: inline-block;
          width: auto;
          text-transform: uppercase;
          border-bottom: 4px solid #832700;
          padding: 10px 0;
        }

        .p {
          text-align: justify;
          font-size: 15px;
          line-height: 18px;
          width: 80%;

          div:nth-child(1) {
            margin-top: 0px;
          }

          div {
            margin-top: 20px;
          }
        }
      }
    }
  }

  #equipe {
    box-sizing: border-box;
    padding: 100px 0px 100px 0px;

    .redline-left {
      border: 0;
      height: 4px;
      background-color: #832700;
      margin-left: 0px;
      text-align: left;
      width: 100px;
    }

    .partenaires {
      text-align: left;

      h3 {
        padding: 50px 0px 10px;
      }

      img {
        padding: 50px 20px 0px 0px;
      }
    }

    .redline {
      border: 0;
      height: 4px;
      background-color: #832700;
      margin-right: 0px;
      width: 10vw;
      margin-top: 20px;
    }

    .team-container {
      margin-top: 100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;

      .fist {
        width: 70px;
        height: 70px;
        border: 0;
      }

      .team-txt {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 40px;
        width: 10%;

        text-transform: uppercase;

        .space-top {
          margin-top: 50px;
        }
      }

      div {
        text-align: center;

        img {
          width: 80%;
          max-width: 350px;
          box-sizing: border-box;
          border-style: solid;
          border-width: 5px;
          border-image: linear-gradient(
              45deg,
              rgb(245, 150, 86),
              rgb(131, 39, 0)
            )
            1;
        }

        h4 {
          padding: 20px 0 0px;
        }

        .post {
          color: #832700;
          padding: 10px 0;
          font-weight: 800;
        }

        svg {
          font-size: 30px;
          color: #fff;
        }

        svg:hover {
          color: #f59656;
        }

        .description {
          padding-top: 10px;
          text-align: left;
          line-height: 20px;
        }
      }

      .coachs {
        width: 40%;
      }

      .box-photo {
        width: 100%;

        &:first-child {
          margin-bottom: 50px;
        }
      }

      .partenaires {
        text-align: center;

        .wrapper-img {
          display: flex;
          align-items: row;
        }
        h3 {
          text-align: left;
        }
        img {
          max-width: 180px;
          border: 0;

          &:nth-child(3) {
          }
        }
      }
    }
  }

  #tarif {
    box-sizing: border-box;
    padding: 100px 0px 100px 0px;

    .redline {
      border: 0;
      height: 4px;
      background-color: #832700;
      margin-right: 0px;
      width: 10vw;
      margin-top: 20px;
    }

    .tarif-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: start;
      width: 100%;
      margin-top: 100px;

      .tab {
        width: 45%;
        padding: 20px;
        box-sizing: border-box;
        background-color: rgba(3, 2, 2, 0.7);
        -webkit-box-shadow: 0px 0px 20px -3px #000000;
        box-shadow: 0px 0px 20px -3px #000000;

        h4 {
          text-align: center;
          padding-bottom: 20px;
          border-bottom: 4px solid #832700;
        }

        .point {
          height: 4px;
          width: 20px;
          background-color: #832700;
          margin-top: 20px;
          margin: 20px auto;
        }

        .offer {
          padding-top: 20px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          p:first-child {
            width: 50%;
            font-size: 1vw;
          }

          .price {
            font-size: 2vw;
            color: #fff;
          }

          .price-small {
            width: 40%;
            text-align: right;
            font-size: 1.5vw;
          }
        }
      }
    }
  }

  .disclaimer-forfaits {
    width: 100%;
    text-align: right;
    padding-top: 10px;
  }

  .parainage {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    background-color: rgba(131, 39, 0, 0.7);
    -webkit-box-shadow: 0px 0px 20px -3px #000000;
    box-shadow: 0px 0px 20px -3px #000000;
    margin-top: 50px;
  }

  .btn-wrapper {
    margin: 100px auto;
    width: 100%;
    text-align: center;
  }

  .btn-resa {
    padding: 20px;
    background-color: #f59656;
    color: #fff;
    transition: background-color 0.5s;
    font-size: 20px;
    font-weight: bold;
  }

  .btn-resa:hover {
    background-color: #fff;
    color: #f59656;
  }

  .calendly-inline-widget {
    width: 100%;
    height: 730px;
  }

  #planning {
    box-sizing: border-box;
    padding: 100px 0px 100px 0px;

    .redline {
      border: 0;
      height: 4px;
      background-color: #832700;
      margin-right: 0px;
      width: 10vw;
      margin-top: 20px;
    }

    img {
      margin-top: 100px;
      width: 100%;
    }

    .peppy-planning {
      margin-top: 20px;

      a {
        color: #fff;
        text-decoration: underline;

        &:hover {
          color: #f59656;
        }
      }
    }

    h3 {
      padding: 30px 0px 0px 10px;
    }
  }

  #contact {
    box-sizing: border-box;
    padding: 100px 0px 100px 0px;

    .redline {
      border: 0;
      height: 4px;
      background-color: #832700;
      margin-right: 0px;
      width: 10vw;
      margin-top: 20px;
    }

    .left-contact {
      width: 100%;
      margin-top: 50px;

      h3 {
        margin-top: 50px;
        margin-bottom: 30px;
      }

      iframe {
        margin-top: 20px;
      }

      .left {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;

        .phone-email {
          width: 50%;
        }

        h3 {
          margin-top: 0;
          margin-bottom: 30px;
        }
      }

      a {
        color: #fff;
        display: flex;

        padding-bottom: 30px;
        font-size: 20px;

        svg {
          padding-right: 10px;
          font-size: 40px;
          margin-top: -10px;
        }
      }

      a:hover {
        color: #f59656;
      }

      img {
        width: 60%;
      }
    }
  }

  footer {
    width: 100%;
    color: #fff;
    background-color: #000;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;

    .span-link {
      color: #fff;
    }

    .span-link:hover {
      cursor: pointer;
      color: #f59656;
    }
  }

  @media screen and (max-width: 1500px) {
    h1 {
      font-size: 90px;
    }

    h2 {
      font-size: 35px;
    }

    h3,
    h4 {
      font-size: 23px;
    }

    .slogan {
      font-size: 40px;
    }

    .wrapper-section {
      max-width: 100%;
      margin: 0 50px 0 200px;
    }

    #solutions {
      .def-container {
        div {
          svg {
            font-size: 75px;
          }
        }
      }
    }

    #tarif {
      .tarif-container {
        .tab {
          .offer {
            p:first-child {
              font-size: 15px;
            }

            .price {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    h1 {
      font-size: 55px;
    }

    h2 {
      font-size: 30px;
    }

    h3,
    h4 {
      font-size: 20px;
    }

    .slogan {
      font-size: 30px;
    }

    #solutions {
      .def-container {
        div {
          flex-direction: column;

          .wrapper-title-icon {
            width: 100%;
          }

          .p {
            width: 100% !important;
          }
        }
      }
    }

    #equipe {
      .redline-left {
        margin: 0 auto;
      }
      .team-container {
        .partenaires {
          h3 {
            text-align: center;
          }
          img {
            margin: 0 30px 0 0;
            padding-right: 0;
          }

          .wrapper-img {
            justify-content: center;
          }
        }
      }

      .team-container {
        flex-direction: column;

        div,
        .coachs {
          width: 100%;
          margin: 0 auto;
          padding-top: 30px;
        }

        .team-txt {
          display: none;
        }
      }
    }

    #tarif {
      .tarif-container {
        flex-direction: column;

        .tab {
          width: 100%;
        }

        .tab:nth-child(1) {
          margin-bottom: 50px;
        }
      }
    }

    #planning {
    }

    #contact {
      .left-contact {
        .left {
          flex-direction: column;

          .phone-email {
            width: 100%;
            position: relative;

            img {
              width: 30%;
              position: absolute;
              right: 0px;
              top: 0px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .video-wrapper {
      width: auto;
      height: 100vh;
      objectfit: cover;
      objectposition: center;
    }

    .wrapper-section {
      margin: 0 20px;
    }

    #objectif {
      .objectif-wrapper {
        justify-content: space-evenly;
      }

      .box {
        width: 150px;
        border-radius: 0%;
        height: 100px;
        margin: 50px 3px 3px 3px;
      }
    }

    #box {
      .opacity-img {
        padding: 100px 20px 100px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .offre-lancement {
          position: absolute;
          bottom: 100px;
          left: 2.5%;
          width: 95%;
          height: auto;
          margin: 0px auto;
          background-color: rgba(245, 150, 86, 1);
          text-align: center;
          padding: 20px 0px;
          display: none;

          p:nth-child(1) {
            font-size: 25px;
            padding-bottom: 10px;
          }

          p:nth-child(2) {
            font-size: 20px;
            font-style: italic;
          }
          div {
            margin: 40px 0px -30px;
          }
        }
      }
    }

    #equipe {
      .team-container {
        div {
          width: 100%;
          max-width: 100%;
        }
        .partenaires {
          img {
            margin-right: 0;
          }
        }

        .wrapper-img {
          flex-direction: column;
        }
      }
    }
    #contact {
      .left-contact {
        .left {
          flex-direction: column;

          .phone-email {
            width: 100%;
            position: relative;

            img {
              display: none;
            }
          }
        }
      }
    }
  }
`;

// == Export
export default PageStyled;
