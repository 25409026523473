// == Import : npm
import { connect } from 'react-redux'

// == Import : local
import App from 'components/App/index.js'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(App)