// == Import locaux
import styled from 'styled-components';


// == Composant
// ici on a la fonction qui décrit le style du composant App
const SimpleMenuStyled = styled.div`
    display: none;

    @media screen and (max-width: 767px) {
        display: block;
        position: fixed;
        z-index: 10;
        width: 100%;
        baox-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: #000;
        padding: 20px 0px;

        .mobile-menu {
            svg {
                color: #f59656;
                font-size: 50px;
            }
        }

        .picto-rs-container {
            a {
                color: #fff;
                padding-left: 10px;

                svg {
                    font-size: 35px;
                }
            }
        }

        img {
            width: 100px;
            padding-right: 10px;
        }
    }
`;

// == Export
export default SimpleMenuStyled;