import React, { Component, useEffect, useLocation, useState } from 'react'
import PropTypes from 'prop-types'
import ReglementStyled from './ReglementStyled'
import { NavLink } from 'react-router-dom'
import ModalLegal from 'components/ModalLegal'
import ScrollToTop from 'components/ScrollToTop'

const Reglement = () => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(prev => !prev);
  };


  return (
    <ReglementStyled>
      <ScrollToTop />
      <ModalLegal showModal={showModal} setShowModal={setShowModal}/>
      <section>
      <h1>RÈGLEMENT INTÉRIEUR CROSSFIT OSSAU</h1>
      <p>Le règlement intérieur a pour objet de préciser les règles de bon fonctionnement. Il s’applique de facto à l’ensemble des personnes ayant souscrit une adhésion avec engagement ou sans engagement à la Box CrossFit Bielle. L’entrée et l’utilisation des installations de la salle sont subordonnées à l’acceptation par les utilisateurs du présent règlement et de l’autorité des responsables chargés de son exécution.</p>
      <p>En cas de non-respect du présent règlement, le personnel de CrossFit OSSAU est habilité à prendre toutes les mesures nécessaires à l’encontre des contrevenants et se réserve le droit de leur interdire l’accès à la salle et à l’ensemble des matériels.</p>
      <h2>ARTICLE 1</h2>
      <p>Tout membre doit, pour son adhésion :</p>
      <ul>
        <li>Renseigner et signer le contrat d’adhésion, les conditions générales de vente ainsi que le présent règlement intérieur;</li>
        <li>Remettre un certificat médical de non contre-indication à la pratique du CrossFit datant de moins de 3 mois;</li>
        <li>Apporter une autorisation parentale pour les mineurs;</li>
        <li>Être à jour de ses cotisations.</li>
      </ul>
      <p>Toute adhésion vaut acceptation des conditions d’adhésion en termes de pratique du CrossFit, de discipline et d’assurance. En outre, durant la pratique sportive, si l’adhérent ne donne pas toutes les informations concernant sa santé, CrossFit OSSAU ne pourra être tenu pour responsable.</p>
      <h2>ARTICLE 2</h2>
      <p>L’accès au WOD se fait par une réservation (via l’application Peppy ou sur place) en fonction des places disponibles sur les différents créneaux horaires (possibilité de réserver sur plusieurs jours à l’avance). Si vous n’êtes pas présent dans les dix premières minutes du cours, votre place peut être réattribuée à une personne présente à la Box et souhaitant participer au WOD. Il est demandé aux adhérents d’annuler au minimum 30 minutes avant le début du créneau horaire initialement réservé afin de permettre à d’autres adhérents de s’inscrire. Dans le cas de la carte 10 séances, si l’annulation se fait dans les délais demandés, la séance annulée ne sera pas décomptée. Si l’annulation se fait à moins de 2 heures de la réservation, le décompte de la séance sera effectif. Si une difficulté est rencontrée pour annuler une réservation, il sera nécessaire d’appeler la Box afin de régler le problème manuellement.
      </p>
      <p>Pour les abonnements, au bout de 3 absences non justifiées, la carte d’accès ainsi que les réservations de l’Adhérent seront bloqués pendant 1 mois.</p>
      <h2>ARTICLE 3</h2>
      <p>La pratique du CrossFit se fait avec une tenue propre et adaptée, ainsi que des chaussures propres.</p>
      <h2>ARTICLE 4</h2>
      <p>L’Adhérent ne pourra s’engager dans une séance si elle est commencée sauf autorisation exceptionnelle de l’encadrant.</p>
      <h2>ARTICLE 5</h2>
      <p>Les personnes non-adhérentes à la Box sont tolérées (uniquement au niveau de la zone d’accueil) à partir du moment où elles respectent les règles de savoir vivre et qu’elles ne dérangent pas le bon déroulement du WOD. Cependant, elles peuvent être refusées si le nombre maximum de personnes autorisées dans la Box est atteint. En cas d’attitude déplacée ou dangereuse envers les adhérents ou l’encadrement, elles se verront exclues de l’enceinte sportive. </p>
      <h2>ARTICLE 6</h2>
      <p>L’utilisateur s’engage à ranger le matériel et le nettoyer à la fin de sa séance afin que chacun puisse y trouver un confort pratique. Il s’engage par la même à respecter les règles de courtoisie inhérentes à la pratique d’activité physique et sportive. 
      Les créneaux « Open Gym» reposent sur une mise à disposition du matériel AVEC ACCORD PRÉALABLE DE L’ENCADREMENT. Le matériel étant professionnel, les coachs doivent vérifier les capacités de l'adhérent à utiliser le matériel seul. L’utilisation des matériels sera alors de la seule responsabilité de l’utilisateur. Lors des créneaux « Open Gym », si un adhérent souhaite faire un WOD initialement programmé, il sera prioritaire sur les autres adhérents qui sont en séance libre. Le coach sera alors en charge de définir les différentes zones de travail. 
      </p>
      <h2>ARTICLE 7</h2>
      <p>Chaque abonné est prié de ne pas oublier son gobelet, bouteille d’eau ou autres déchets avant de quitter la salle ou de les jeter dans une des poubelles prévues à cet effet. En cas d’oubli d’effets vestimentaires, ces derniers seront mis dans une panière à linge afin que vous les récupérez lors de la séance suivante. Les coachs ne sont pas responsables de la redistribution des effets vestimentaires.</p>
      <h2>ARTICLE 8</h2>
      <p>La nourriture est interdite dans la zone d'entraînement (sauf boisson) ainsi que dans les vestiaires. Elle est tolérée dans la zone d’accueil. Il est interdit de fumer ou de vapoter sur l’ensemble du site.</p>
      <h2>ARTICLE 9</h2>
      <p>L’apport et/ou la consommation de stupéfiants au sein des locaux CrossFit Bielle est strictement interdit et entraînera l’exclusion définitive de l’Adhérent.</p>
      <h2>ARTICLE 10</h2>
      <p>Pour des raisons d’hygiène, il est demandé aux adhérents de maintenir propres les vestiaires et les sanitaires mis à disposition. </p>
      <h2>ARTICLE 11</h2>
      <p>Pour des raisons de sécurité, la présence d’enfants n’est pas admise dans les zones d’entraînements. Ils pourront accéder à la zone d’accueil mais resteront sous la surveillance et la responsabilité de leurs parents.</p>
      <h2>ARTICLE 12</h2>
      <p>Les animaux ne sont pas admis dans la Box, même tenus en laisse.</p>
      <h2>ARTICLE 13</h2>
      <p>Les vestiaires ne font l’objet d’aucune surveillance. L’Adhérent reconnaît avoir été informé des risques encourus du fait de placer des objets de valeur dans les vestiaires communs. La Box CrossFit Bielle ne pourra être tenue responsable en cas de perte ou de vol.</p>
      <h2>ARTICLE 14</h2>
      <p>La magnésie est gracieusement mise à disposition des adhérents par la Box. CEPENDANT, il est demandé de ne pas en abuser et de l’utiliser en quantité raisonnable et seulement sur les mouvements nécessaires. Ceci n’empêche pas les adhérents de ramener leur propre magnésie. En cas de sol souillé, il sera demandé aux adhérents, à l’issue de la séance, de nettoyer leur zone de travail (voir ARTICLE 6).</p>
      <h2>ARTICLE 15</h2>
      <p>Les téléphones portables devront être mis en mode silencieux durant le WOD sauf accord ponctuel d’un coach. Le consentement n’étant pas systématique, l’Adhérent devra renouveler sa demande à chaque séance.</p>
      <h2>ARTICLE 16</h2>
      <p>Consigne de sécurité : (Pompier n°18, Police n°17, SAMU n°15). Chaque adhérent s’engage en cas d’accident dont il serait témoin à alerter immédiatement les coachs (formés aux gestes de premiers secours) ou à défaut les services de secours. </p>
      <h2>ARTICLE 17</h2>
      <p>La Box se réserve le droit de modifier occasionnellement les heures d’ouverture après avoir informé les adhérents.</p>
      <h2>ARTICLE 18</h2>
      <p>Le non-respect d’une des règles citées supra fera l’objet d’un avertissement de l’équipe encadrante. Une récidive pourra entraîner l’exclusion provisoire voire définitive de l’Adhérent. L’abonné déclare se conformer au présent Règlement Intérieur, y adhérer sans restriction, ni réserve.</p>
      <p>Ces règles, fondamentales à l’esprit CrossFit Bielle et jointes à votre contrat, constituent son Règlement Intérieur. En signant cette page vous vous engagez à les respecter. Le cas échéant, nous ne pourrons renouveler votre adhésion le mois suivant.</p>
      </section>
      <footer>Copyright Crossfit Bielle 2021<span> | </span>
        <span className="span-link" onClick={openModal}>Mentions Légales</span><span> | </span>
        <NavLink to="/conditions-generales-de-vente" className="span-link">Conditions générales de vente</NavLink><span> | </span>
        <NavLink to="/reglement-interieur" className="span-link">Règlement intérieur</NavLink>
      </footer>
    </ReglementStyled>
  )
}

Reglement.propTypes = {
}

export default Reglement